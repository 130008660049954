import React from 'react';
import { Router } from '@reach/router';

import Home from '../clientsidePage/home';
import Blog from '../clientsidePage/blog';
import Pictures from '../clientsidePage/pictures';
import FamilyMembers from '../clientsidePage/familyMembers';
import { login, isUserAuthenticated, getUserProfile } from '../utils/auth';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAuthenticated: false,
      user: null,
    };
  }

  async componentDidMount() {
    const isAuthenticated = await isUserAuthenticated();
    this.setState({ isAuthenticated, isLoading: false });
    if (isAuthenticated) {
      const user = await getUserProfile();
      this.setState({ user });
    }
  }

  render() {
    const { isLoading, isAuthenticated, user } = this.state;
    if (isLoading) {
      return <p>Loading app...</p>;
    }

    if (!isAuthenticated) {
      login();
      return <p>Redirecting to login...</p>;
    }

    // NOTE: Make sure this mirrors the Navbar
    return (
      <Router>
        <Home path="/app" user={user} />
        <Blog path="/app/blog" />
        <FamilyMembers path="/app/family-members" />
        <Pictures path="/app/pictures" />
      </Router>
    );
  }
}

export default App;
