import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import BlogPostCard from '../components/blogPostCard';

const Blog = () => {
  const data = useStaticQuery(graphql`
    query AllBlogPosts {
      allContentfulBlogPost(sort: { fields: date, order: DESC }) {
        totalCount
        edges {
          node {
            id
            date(formatString: "DD MMMM, YYYY")
            mainImage {
              title
              file {
                url
              }
            }
            title
            twoSentenceSummary
            fields {
              fullSlug
            }
          }
        }
      }
    }
  `);

  const props = {
    title: 'Blog | The Cao Farm',
    jumbotronTitle: 'Blog',
    jumbotronCaption: 'Look at recent blog posts',
  };

  return (
    <Layout {...props}>
      <h4>{data.allContentfulBlogPost.totalCount} Total Posts</h4>
      <div className="row">
        {data.allContentfulBlogPost.edges.map(({ node }) => {
          const props = {
            imageTitle: node.mainImage.title,
            imageUrl: node.mainImage.file.url,
            postDate: node.date,
            postSlug: node.fields.fullSlug,
            postTitle: node.title,
            postTwoSentenceSummary: node.twoSentenceSummary,
          };

          return (
            <div key={node.id} className="col-12 col-sm-6 col-lg-4 mb-3">
              <BlogPostCard {...props} />
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Blog;
