import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const Home = ({ user }) => {
  let name = '';
  if (user && user.given_name) {
    name = user.given_name;
  } else if (user && user.name) {
    name = user.name;
  } else {
    name = 'Cao Farmer';
  }

  const props = {
    title: 'Home Page | The Cao Farm',
    jumbotronTitle: 'Home Page',
    jumbotronCaption: `Hi ${name}! You're at the homepage, but there's not much else here right now...`,
  };

  return (
    <Layout {...props}>
      <div>
        <h3>
          Go to the <Link to="/app/blog">Blog page</Link> for the latest blog
          posts.
        </h3>
        <h3>
          Go to the <Link to="/app/family-members">Family Members page</Link> to
          see everyone in the family.
        </h3>
        <h3>
          Check out the <Link to="/app/pictures">Pictures page</Link> to see all
          the pictures we've uploaded.
        </h3>
      </div>
    </Layout>
  );
};

export default Home;
