import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';

const FamilyMembers = () => {
  const data = useStaticQuery(graphql`
    query AllPersons {
      allContentfulPerson {
        edges {
          node {
            id
            name
            profilePicture {
              file {
                url
              }
              title
            }
            fields {
              fullSlug
            }
          }
        }
      }
    }
  `);

  const props = {
    title: 'Family Members | The Cao Farm',
    jumbotronTitle: 'Family Members',
    jumbotronCaption: `Here are all the Cao Farmers!`,
  };

  return (
    <Layout {...props}>
      <div className="row">
        {data.allContentfulPerson.edges.map(({ node }) => {
          return (
            <div key={node.id} className="col-12 col-sm-6 col-lg-4 mb-3">
              <div className="card">
                <Link to={node.fields.fullSlug}>
                  <img
                    src={'https:' + node.profilePicture.file.url}
                    className="card-img-top"
                    alt={node.profilePicture.title}
                  />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">{node.name}</h5>
                  <Link className="card-link" to={node.fields.fullSlug}>
                    <h6 className="card-subtitle mb-2">{`Go to ${node.name}'s page`}</h6>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default FamilyMembers;
