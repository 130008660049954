import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import PictureCard from '../components/pictureCard';

const Pictures = () => {
  const data = useStaticQuery(graphql`
    query AllAssets {
      allContentfulAsset {
        edges {
          node {
            description
            file {
              url
            }
            id
            title
          }
        }
        totalCount
      }
    }
  `);

  const props = {
    title: 'Pictures | The Cao Farm',
    jumbotronTitle: 'Pictures',
    jumbotronCaption: 'Look at all those pictures',
  };

  return (
    <Layout {...props}>
      <h3>{data.allContentfulAsset.totalCount} Total Pictures</h3>
      <p className="mb-5">
        <strong>NOTE:</strong> If you can, please go into Contentful and add
        dates and descriptions to pictures. It helps to know when the picture
        was taken and what it's about.
      </p>
      <div className="row">
        {data.allContentfulAsset.edges.map(({ node }) => {
          const props = {
            title: node.title,
            description: node.description,
            url: node.file.url,
          };

          return (
            <div
              key={node.id}
              className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3"
            >
              <PictureCard {...props} />
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Pictures;
