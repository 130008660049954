import React from 'react';
import PropTypes from 'prop-types';

const PictureCard = ({ title, description, url }) => {
  return (
    <div className="card">
      <img src={'https:' + url} className="card-img-top" alt={title} />
      <div className="card-body">
        <h6 className="card-title">
          <strong>Title:</strong> {title}
        </h6>
        <p className="card-text">
          <strong>Description:</strong> {description}
        </p>
      </div>
    </div>
  );
};

PictureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default PictureCard;
